"use client";
import "./post-carousel.modules.scss";
import CarouselItem from "./carousel-item";
import { useEffect, useState, useRef } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils";
import Link from "next/link";

export default function PostCarousel({ posts }: any) {
  const [startItemActive, setStartItemActive] = useState(0);
  const [endItemActive, setEndItemActive] = useState(2); // Track the active index
  const [itemWidth, setItemWidth] = useState<any>();
  const [translateX, setTranslateX] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(6);
  const carouselWrapperRef = useRef(null);

  useEffect(() => {
    const calculateItemsToShow = () => {
      if (!carouselWrapperRef.current) {
        return;
      }
      const carouselWidth = (carouselWrapperRef.current as any)?.clientWidth;
      let itemsToShow = 3;
      if (carouselWidth > 780 && carouselWidth < 1024) {
        itemsToShow = 5;
      } else if (carouselWidth >= 1024) {
        itemsToShow = 6;
      }

      setItemsToShow(itemsToShow);
      const screenWidth = carouselWidth - 48;
      const itemWidth = Math.floor(screenWidth / itemsToShow);

      setItemWidth(itemWidth);
      // Reset end item;
      const endItemActive = 0 + itemsToShow;
      setEndItemActive(endItemActive);
    };

    calculateItemsToShow(); // Initial calculation
    window.addEventListener("resize", calculateItemsToShow); // Update on resize

    return () => {
      window.removeEventListener("resize", calculateItemsToShow); // Cleanup listener
    };
  }, []);

  const movingCarousel = (index: number) => {
    const pageIndex = Math.floor(index / itemsToShow);
    // Calculate the translateX value based on the active index
    let translateX = 0;
    if (pageIndex > 0) {
      translateX = -pageIndex * (itemWidth * itemsToShow);
    }
    const startItemActive = pageIndex * itemsToShow;
    const endItemActive = startItemActive + itemsToShow;
    setStartItemActive(startItemActive);
    setEndItemActive(endItemActive);
    setTranslateX(translateX);
  };

  // Handle next and previous button clicks
  const handleNext = () => {
    const newStartIndex = startItemActive + itemsToShow;
    if (newStartIndex < posts.length) {
      movingCarousel(newStartIndex);
    }
  };

  const handlePrevious = () => {
    const newStartIndex = startItemActive - itemsToShow;
    if (newStartIndex >= 0) {
      movingCarousel(newStartIndex);
    }
  };

  return (
    <div
      className="carousel-wrapper relative my-8 px-6"
      ref={carouselWrapperRef}
    >
      <div
        className={cn("carousel-items transition-all duration-300")}
        style={{
          transform: `translateX(${translateX}px)`,
        }}
      >
        {(posts || []).map((item: any, index: number) => {
          return (
            <Link
              key={index}
              href={`/posts/${item.slug}`}
              style={{ width: `${itemWidth ?? 150}px` }}
            >
              <CarouselItem
                imageUrl={item.image}
                title={item.title}
                style={{
                  opacity:
                    index < startItemActive || index > endItemActive
                      ? "0.2"
                      : "1",
                  width: "100%",
                  transition: "opacity 0.3s ease",
                  padding: "0 8px", // Smooth opacity transition
                }}
              />
            </Link>
          );
        })}
      </div>
      {startItemActive != 0 && (
        <button
          className="absolute left-0 top-1/2 h-full -translate-y-1/2 transform bg-white/30 p-2 shadow-md"
          onClick={handlePrevious}
          disabled={startItemActive === 0} // Disable if at the start
        >
          <ChevronLeft />
        </button>
      )}
      {endItemActive < posts.length && (
        <button
          className="absolute right-0 top-1/2 h-full -translate-y-1/2 transform bg-white/30 p-2 shadow-md"
          onClick={handleNext}
          disabled={endItemActive >= posts.length} // Disable if at the end
        >
          <ChevronRight />
        </button>
      )}
    </div>
  );
}

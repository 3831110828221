"use client";

import { ArrowLeft } from "lucide-react";

export default function FabBack() {
  return (
    <button
      onClick={() => window.history.back()}
      className="fixed bottom-[145px] right-4 flex h-[56px] w-[56px] transform items-center justify-center rounded-full bg-gray-300 text-white p-4 shadow-lg transition-transform hover:scale-105"
    >
      <ArrowLeft />
    </button>
  );
}

import React from "react";
import Form, { FormItemProps } from "antd/es/form";

import { AppConsts } from "src/lib/constants";
import InputAddress from "./InputAddress";

const { formVerticalLayout } = AppConsts;
interface FormInputAddressProps extends FormItemProps {
  label?: string;
  name: string | string[] | any[];
  rule?: any;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  formClass?: string;
  size?: "middle" | "small" | "large";
  type?: string;
  dependencies?: string[];
  countries: any;
  cities: any;
  wards: any;
  onChange?: (value: any) => void;
  extra?: any;
}

const FormInputAddress: React.FC<FormInputAddressProps> = ({
  label,
  name,
  extra,
  rule,
  placeholder,
  disabled,
  className = "w-full rounded-md text-sm",
  formClass,
  size,
  type,
  onChange,
  countries,
  cities,
  wards,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rule}
      className={"w-full" + (formClass ? " " + formClass : "")}
      extra={extra}
      {...formVerticalLayout}
    >
      <InputAddress
        disabled={disabled}
        className={className}
        size={size ?? "large"}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        countries={countries}
        cities={cities}
        wards={wards}
      />
    </Form.Item>
  );
};

export default FormInputAddress;
